import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2 text-center text-neutral-dark" }
const _hoisted_2 = { class: "font-bold text-xl pb-4 break-word-wrap" }
const _hoisted_3 = { class: "break-word-wrap" }
const _hoisted_4 = { class: "dialog-footer flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.isOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isOpen) = $event)),
    width: "30%",
    onClose: $setup.closeApproveModal,
    "align-top": ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("button", {
          type: "button",
          onClick: _withModifiers($setup.handleApprove, ["prevent"]),
          class: "btn bg-green-secondary hover:bg-green-primary text-white py-2 w-24 rounded-lg"
        }, " Approve ")
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, " Approval for \"" + _toDisplayString($props.companyName) + "\" ", 1),
        _createElementVNode("p", _hoisted_3, " You are about to approve the account registration for \"" + _toDisplayString($props.companyName) + "\". ", 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}