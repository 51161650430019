import { defineComponent as _defineComponent } from 'vue'
import TheClock from "@/components/TheClock.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import TenantRejectFormDialog from "@ems/containers/Tenant/TheForm/TenantReject.Form.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { REHASH_ACCOUNT_ACTION } from "@ems/constants/rehash_account_action";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import TenantDetailForm from "@ems/containers/Tenant/TheForm/TenantDetail.Form.vue";
import { ElMessage } from "element-plus";
import { ITenantRegistration } from "@/models/Tenant";
import TenantApproveFormDialog from "@ems/containers/Tenant/TheForm/TenantApprove.Form.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Details',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();
const tenantData = ref<ITenantRegistration>();
const tenantId = ref("");
const isRejectModalOpen = ref(false);
const isApproveModelOpen = ref(false);
const isSubmitting = ref(false);
const isLoading = ref(false);

const getIsLoadingUpdateTenantStatus = computed(
  () => TenantUserModule.isLoadingUpdateTenantStatusGetter
);

const updateTenantData = (data: ITenantRegistration) => {
  tenantData.value = data;
};

const openRejectModal = () => {
  isRejectModalOpen.value = true;
};

const openApproveModal = () => {
  isApproveModelOpen.value = true;
};

const handleBack = () => {
  router.push(`/admin`);
};

watch(tenantData, (newValues) => {
  if (!newValues) {
    isLoading.value = true;
  }
});

onMounted(() => {
  tenantId.value =
    typeof route.params.tenantId === "string" ? route.params.tenantId : "";
});

const __returned__ = { router, route, tenantData, tenantId, isRejectModalOpen, isApproveModelOpen, isSubmitting, isLoading, getIsLoadingUpdateTenantStatus, updateTenantData, openRejectModal, openApproveModal, handleBack, TheClock, TheFooter, TheHeader, TenantRejectFormDialog, BaseSpinner, computed, onMounted, ref, watch, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get REHASH_ACCOUNT_ACTION() { return REHASH_ACCOUNT_ACTION }, get TenantUserModule() { return TenantUserModule }, get STATUS_TENANT() { return STATUS_TENANT }, TenantDetailForm, get ElMessage() { return ElMessage }, get ITenantRegistration() { return ITenantRegistration }, TenantApproveFormDialog }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})