import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2 text-center text-neutral-dark" }
const _hoisted_2 = { class: "font-bold text-xl pb-4 break-word-wrap" }
const _hoisted_3 = { class: "break-word-wrap" }
const _hoisted_4 = { class: "dialog-footer flex items-center justify-center" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "validation-schema": $setup.schema,
    onSubmit: $setup.onSubmit,
    "label-position": "top",
    "initial-values": $setup.initialData
  }, {
    default: _withCtx(({ values, isSubmitting, resetForm }) => [
      _createElementVNode("div", {
        class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
      }, [
        _createVNode(_component_el_dialog, {
          modelValue: $setup.isOpen,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isOpen) = $event)),
          width: "30%",
          onClose: ($event: any) => ($setup.closeRejectModal(resetForm)),
          "align-top": ""
        }, {
          footer: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, [
              _createElementVNode("button", {
                type: "submit",
                disabled: !values.RejectionReason || isSubmitting,
                class: "btn bg-red-primary hover:bg-red-600 text-white py-2 w-24 rounded-lg"
              }, " Reject ", 8, _hoisted_5)
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h2", _hoisted_2, " Rejection for \"" + _toDisplayString($props.companyName) + "\" ", 1),
              _createElementVNode("p", _hoisted_3, " You are about to reject the account registration for \"" + _toDisplayString($props.companyName) + "\". ", 1),
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mb-6" }, "Please enter the reason below.", -1)),
              _createVNode($setup["Field"], { name: "RejectionReason" }, {
                default: _withCtx(({ value, field, errorMessage, meta }) => [
                  _createVNode(_component_el_form_item, {
                    error: meta.touched && errorMessage ? errorMessage : '',
                    label: "Reason for Rejection",
                    "label-position": "top",
                    class: "flex flex-col label-custom",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({
                        type: "textarea",
                        placeholder: "Enter Reason",
                        rows: "5",
                        "show-word-limit": ""
                      }, field, {
                        "model-value": value,
                        resize: "none",
                        maxlength: "255",
                        class: "w-full custom-placeholder"
                      }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 2
        }, 1032, ["modelValue", "onClose"])
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}