import { createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bg-neutral-light py-5" }
const _hoisted_2 = { class: "pt-6" }
const _hoisted_3 = { class: "flex items-center justify-center gap-4 mb-8" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["TenantDetailForm"], {
        "is-show-status-remark": true,
        tenantId: $setup.route.params.tenantId,
        "onUpdate:tenantData": $setup.updateTenantData
      }, null, 8, ["tenantId"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "btn bg-white hover:bg-gray-100 text-neutral-black border border-neutral-black py-2 w-24 rounded-lg .fullscreen.lock",
        onClick: _withModifiers($setup.handleBack, ["prevent"])
      }, " Back "),
      ($setup.tenantData && $setup.tenantData?.Status == $setup.STATUS_TENANT.Pending)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn bg-red-primary hover:bg-red-600 text-white py-2 w-24 rounded-lg",
            onClick: _withModifiers($setup.openRejectModal, ["prevent"]),
            disabled: $setup.isSubmitting
          }, " Reject ", 8, _hoisted_4))
        : _createCommentVNode("", true),
      ($setup.tenantData && $setup.tenantData?.Status == $setup.STATUS_TENANT.Pending)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn--green-primary hover:bg-green-600 text-white py-2 w-24 rounded-lg",
            onClick: _withModifiers($setup.openApproveModal, ["prevent"]),
            disabled: $setup.isSubmitting
          }, " Approve ", 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _createVNode($setup["TenantRejectFormDialog"], {
      tenantId: $setup.tenantId,
      isRejectModalOpen: $setup.isRejectModalOpen,
      "onUpdate:isRejectModalOpen": _cache[0] || (_cache[0] = ($event: any) => (($setup.isRejectModalOpen) = $event)),
      isLoading: $setup.isLoading,
      "onUpdate:isLoading": _cache[1] || (_cache[1] = ($event: any) => (($setup.isLoading) = $event)),
      companyName: $setup.tenantData?.CompanyName
    }, null, 8, ["tenantId", "isRejectModalOpen", "isLoading", "companyName"]),
    _createVNode($setup["TenantApproveFormDialog"], {
      "tenant-id": $setup.tenantId,
      isApproveModelOpen: $setup.isApproveModelOpen,
      "onUpdate:isApproveModelOpen": _cache[2] || (_cache[2] = ($event: any) => (($setup.isApproveModelOpen) = $event)),
      isLoading: $setup.isLoading,
      "onUpdate:isLoading": _cache[3] || (_cache[3] = ($event: any) => (($setup.isLoading) = $event)),
      companyName: $setup.tenantData?.CompanyName
    }, null, 8, ["tenant-id", "isApproveModelOpen", "isLoading", "companyName"])
  ])), [
    [
      _directive_loading,
      $setup.isLoading,
      void 0,
      {
        fullscreen: true,
        lock: true
      }
    ]
  ])
}