import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref, watch } from "vue";
import { Form, Field } from "vee-validate";
import TenantUserModule from "../TenantUser.module";
import { REHASH_ACCOUNT_ACTION } from "@ems/constants/rehash_account_action";
import { object, string } from "yup";
import { ElMessage } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantReject.Form',
  props: {
  isRejectModalOpen: Boolean,
  isLoading: Boolean,
  companyName: String,
  tenantId: String,
},
  emits: ["update:isRejectModalOpen", "update:isLoading"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

const initialData = {
  RejectionReason: "",
};

const isOpen = ref<boolean>(false);

const schema = object({
  RejectionReason: string()
    .required("Rejection Reason is required")
    .max(255, "Rejection Reason must be at most 255 characters"),
});

const onSubmit = async (
  values: { RejectionReason: string },
  { resetForm }: { resetForm: any }
) => {
  emit("update:isLoading", true);
  await TenantUserModule.updateTenantStatus({
    TenantId: props.tenantId!,
    Action: REHASH_ACCOUNT_ACTION.Reject,
    RemarkContent: values.RejectionReason,
  });

  closeRejectModal(resetForm);

  if (TenantUserModule.dataUpdateTenantStatusGetter) {
    window.location.reload();
  } else {
    ElMessage.error(TenantUserModule.errorUpdateTenantStatusGetter.message);
  }
  emit("update:isLoading", false);
};

const closeRejectModal = (resetForm: any) => {
  isOpen.value = false;
  emit("update:isRejectModalOpen", false);
  resetForm();
};

watch(
  () => props.isRejectModalOpen,
  (newVal) => {
    isOpen.value = newVal;
  }
);

const __returned__ = { props, emit, initialData, isOpen, schema, onSubmit, closeRejectModal, onMounted, ref, watch, get Form() { return Form }, get Field() { return Field }, get TenantUserModule() { return TenantUserModule }, get REHASH_ACCOUNT_ACTION() { return REHASH_ACCOUNT_ACTION }, get object() { return object }, get string() { return string }, get ElMessage() { return ElMessage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})