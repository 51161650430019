import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from "vue";
import TenantUserModule from "../TenantUser.module";
import { REHASH_ACCOUNT_ACTION } from "@ems/constants/rehash_account_action";
import { ElMessage } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantApprove.Form',
  props: {
  isApproveModelOpen: Boolean,
  isLoading: Boolean,
  companyName: String,
  tenantId: String,
},
  emits: ["update:isApproveModelOpen", "update:isLoading"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

const isOpen = ref<boolean>(false);

const handleApprove = async () => {
  emit("update:isLoading", true);
  await TenantUserModule.updateTenantStatus({
    TenantId: props.tenantId!,
    Action: REHASH_ACCOUNT_ACTION.Approve,
  });

  closeApproveModal();

  if (TenantUserModule.dataUpdateTenantStatusGetter) {
    window.location.reload();
  } else {
    ElMessage.error(TenantUserModule.errorUpdateTenantStatusGetter.message);
  }
  emit("update:isLoading", false);
};

const closeApproveModal = () => {
  isOpen.value = false;
  emit("update:isApproveModelOpen", false);
};

watch(
  () => props.isApproveModelOpen,
  (newVal) => {
    isOpen.value = newVal;
  }
);

const __returned__ = { props, emit, isOpen, handleApprove, closeApproveModal, ref, watch, get TenantUserModule() { return TenantUserModule }, get REHASH_ACCOUNT_ACTION() { return REHASH_ACCOUNT_ACTION }, get ElMessage() { return ElMessage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})